@defer (on viewport) {

  <div fxLayout="column"
       fxLayoutAlign="center center">
    <mat-card appearance="outlined">
      <mat-card-content>
        <div fxLayout="row"
             fxLayoutAlign="center center">
          <div class="welcome"
               fxLayout="column"
               fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column"
               fxLayoutAlign="center center"
               fxLayoutAlign.md="center center" fxLayoutAlign.sm="center center" fxLayoutAlign.xs="center center">
            <div fxFlex="100"
                 fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                 fxLayout="row"
                 fxLayoutAlign="center center">
              <p>
                Estamos escribiendo el código para algo increíble. Vuelve pronto para descubrir nuestra magia
                tecnológica.
              </p>

            </div>
            <div fxFlex="100"
                 fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                 fxLayout="row"
                 fxLayoutAlign="center center">
              <p>
                <span class="highlight">¡La innovación está en <strong>construcción</strong>!</span>
              </p>
            </div>
          </div>
        </div>
        <mat-card-footer>
          <p>Visitanos en <a href="https://www.linkedin.com/company/etribe-mx/">LinkedIn</a></p>
        </mat-card-footer>
      </mat-card-content>
    </mat-card>

    <div style="height: 10px"></div>
    <mat-divider></mat-divider>

    <mat-card appearance="outlined">
      <mat-card-content>
        <div fxLayout="row"
             fxLayoutAlign="center center">
          <div fxLayout="row"
               fxLayoutAlign="center center">
            <div class="welcome"
                 fxLayout="column"
                 fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column"
                 fxLayoutAlign="center center"
                 fxLayoutAlign.md="center center" fxLayoutAlign.sm="center center" fxLayoutAlign.xs="center center">
              <div fxFlex="100"
                   fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                   fxLayout="row"
                   fxLayoutAlign="center center">
                <p>
                  We're writing the code for something amazing. Check back soon to discover our technological
                  wizardry.
                </p>

              </div>
              <div fxFlex="100"
                   fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                   fxLayout="row"
                   fxLayoutAlign="center center">
                <p>
                  <span class="highlight"><strong>Innovation</strong> is under construction!</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <mat-card-footer>
          <p>Visit us at <a href="https://www.linkedin.com/company/etribe-mx/">LinkedIn</a></p>
        </mat-card-footer>
      </mat-card-content>
    </mat-card>

  </div>

} @placeholder {
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
}
