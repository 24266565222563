import {Component, OnInit} from '@angular/core';
import {FlexModule} from "@angular/flex-layout";
import {MatIcon} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatCard, MatCardContent, MatCardFooter} from "@angular/material/card";
import {MatDivider} from "@angular/material/divider";
import {MatMiniFabButton} from "@angular/material/button";

@Component({
  selector: 'et-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatIcon,
    MatProgressSpinner,
    MatCardContent,
    MatCard,
    MatDivider,
    MatMiniFabButton,
    MatCardFooter
  ],
})
export class AppComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
